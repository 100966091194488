/** @format */

// Body
$body-bg: #f3f3f4;

// Typography
$font-family-sans-serif: "Roboto", sans-serif;
$font-color: #333;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #1b87fb;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$input-padding-x: 0.5rem;
$input-padding-y: 0.5rem;

$form-label-font-weight: bold;
// $form-label-font-size: 1.1rem;
$input-font-size: 1.1rem;

$sidebar-bg: #ffffff;
$sidebar-width: 64px;

$settings-tab-width: 300px;
