/** @format */

// Variables
@import "variables";

@import "bootstrap/scss/bootstrap";

// Bootstrap
// @import "bootstrap/dist/css/bootstrap.css";

// toastify
@import "~react-toastify/dist/ReactToastify.css";

.auth-container {
  height: 100vh;
  padding: 0;
  margin: 0;
  // label {
  //   font-weight: $form-label-font-weight;
  //   font-size: $form-label-font-size;
  //   margin-bottom: 2px;
  // }
}
.navbar-nav .nav-item {
  margin-right: 20px;
  .active {
    border-right-color: #fff;
    color: $secondary;
    // background-color: black;
    border-bottom-color: $border-color;
  }
  :not(.active) {
    cursor: pointer;
  }
}

.react-tel-input {
  .form-control {
    width: 100% !important;
    // height: 100% !important;
  }
}

.swal2-popup {
  width: 60% !important ;
  @media (max-width: 480px) {
    width: 90% !important ;
  }
}
.cursor-pointer {
  cursor: pointer;
}

#notification {
  border: $border-color;
  padding: 10px;
  background-color: $border-color;
}

.custom-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7875rem;
  border-radius: 0.2rem;
}
